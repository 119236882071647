<template>
  <div class="px-4 flex items-center text-teal mb-5 cursor-pointer w-34" @click="goBack">
    <span class="material-icons-outlined"> arrow_back </span>
    go Back
  </div>
  <div class="text-white bg-darkGrey p-4 mt-2 mx-4   ">
    <template v-if="isLoading">
      Loading...
    </template>
    <template v-else>
      <span>
        <button :disabled="page <= 1" @click="page--">❮</button>

        {{ page }} / {{ pageCount }}

        <button :disabled="page >= pageCount" @click="page++">❯</button>
      </span>
    </template>
  </div>

  <div class="pt-6 px-4 ">
    <vue-pdf-embed ref="pdfRef" :source="pdfSource" :page="page" @rendered="handleDocumentRender" />
  </div>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed";
export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      pageCount: 1,
      pdfSource: this.$route.query.policy,
    };
  },

  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    goBack() {
      this.$router.push({ name: "re-employee-hand-books" });
    },
  },
};
</script>

<style scoped>
canvas {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
